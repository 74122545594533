/**
 * Converts string or number to object with value unit properties
 * e.g. '12px' becomes { value: 12, unit: 'px' }
 *      12 becomes { value: 12, unit: '' }
 *
 * @param {string} value
 * @return {(object|undefined)| value unit object
 */
export function splitUnits(value) {
  if (
    value === undefined ||
    typeof value !== 'string' ||
    value === ''
  ) {
    return { value, unit: '' };
  }

  // Ratio values like '1:1 Square' are used as unitless strings
  const isRatio = /\d+:\d+.*/.test(value);
  if (isRatio) {
    return { value, unit: '' };
  }

  // Digit+unit like 420px
  const split = value.match(/^([-.\d]+(?:\.\d+)?)(.*)$/);

  // No unit
  if (!split || split.length === 0) {
    return { value, unit: '' };
  }

  return {
    value: parseFloat(split[1].trim()),
    unit: split[2].trim(),
  };
}

/**
 * @param {string} str /// or %%%%%
 * @return {string} / or %
 */
export function collapseChar(str = '', token = '%') {
  const regex = new RegExp(`${token}+`, 'g');
  return str.replace(regex, token);
}
