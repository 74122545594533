/**
  Holds a set of handlebars helpers for comparing values
  @module squarespace-hb-compare
*/
YUI.add('squarespace-hb-compare', function(Y) {

  /**
     If Equals
     @helper if_eq this compare=that
  */
  Y.Handlebars.registerHelper('if_eq', function(context, options) {
    if (context === options.hash.compare) {return options.fn(this);}
    return options.inverse(this);
  });

  /**
     Unless Equals
     @helper unless_eq this compare=that
  */
  Y.Handlebars.registerHelper('unless_eq', function(context, options) {
    if (context === options.hash.compare) {return options.inverse(this);}
    return options.fn(this);
  });


  /**
     If Greater Than
     @helper if_gt this compare=that
  */
  Y.Handlebars.registerHelper('if_gt', function(context, options) {
    if (context > options.hash.compare) {return options.fn(this);}
    return options.inverse(this);
  });

  /**
     Unless Greater Than
     @helper unless_gt this compare=that
  */
  Y.Handlebars.registerHelper('unless_gt', function(context, options) {
    if (context > options.hash.compare) {return options.inverse(this);}
    return options.fn(this);
  });


  /**
     If Less Than
     @helper if_lt this compare=that
  */
  Y.Handlebars.registerHelper('if_lt', function(context, options) {
    if (context < options.hash.compare) {return options.fn(this);}
    return options.inverse(this);
  });

  /**
     Unless Less Than
     @helper unless_lt this compare=that
  */
  Y.Handlebars.registerHelper('unless_lt', function(context, options) {
    if (context < options.hash.compare) {return options.inverse(this);}
    return options.fn(this);
  });


  /**
     If Greater Than or Equal To
     @helper if_gteq this compare=that
  */
  Y.Handlebars.registerHelper('if_gteq', function(context, options) {
    if (context >= options.hash.compare) {return options.fn(this);}
    return options.inverse(this);
  });

  /**
     Unless Greater Than or Equal To
     @helper unless_gteq this compare=that
  */
  Y.Handlebars.registerHelper('unless_gteq', function(context, options) {
    if (context >= options.hash.compare) {return options.inverse(this);}
    return options.fn(this);
  });


  /**
     If Less Than or Equal To
     @helper if_lteq this compare=that
  */
  Y.Handlebars.registerHelper('if_lteq', function(context, options) {
    if (context <= options.hash.compare) {return options.fn(this);}
    return options.inverse(this);
  });

  /**
     Unless Less Than or Equal To
     @helper unless_lteq this compare=that
  */
  Y.Handlebars.registerHelper('unless_lteq', function(context, options) {
    if (context <= options.hash.compare) {return options.inverse(this);}
    return options.fn(this);
  });

}, '1.0', { requires: [
  'handlebars-base'
] });
