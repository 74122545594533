/* eslint-disable */
// Generated from seed-templates/handlebars-module-template.js
YUI.add('squarespace-tourdates-bandsintown-list-template', function(Y) {
  var Handlebars = Y.Handlebars;

  (function() { var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {}; templates["tourdates-bandsintown-list.html"] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this, blockHelperMissing=helpers.blockHelperMissing;

function program1(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n    <div class=\"sqs-tourdates__noItems\">"
    + escapeExpression(((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.loading)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n  ";
  return buffer;
  }

function program3(depth0,data,depth1) {
  
  var buffer = "", stack1, stack2;
  buffer += "\n    ";
  stack2 = helpers['if'].call(depth0, ((stack1 = depth0.tourdates),stack1 == null || stack1 === false ? stack1 : stack1.length), {hash:{},inverse:self.program(34, program34, data),fn:self.programWithDepth(4, program4, data, depth0, depth1),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n  ";
  return buffer;
  }
function program4(depth0,data,depth1,depth2) {
  
  var buffer = "", stack1, options;
  buffer += "\n      ";
  options = {hash:{},inverse:self.noop,fn:self.programWithDepth(5, program5, data, depth0, depth1, depth2),data:data};
  if (stack1 = helpers.tourdates) { stack1 = stack1.call(depth0, options); }
  else { stack1 = depth0.tourdates; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  if (!helpers.tourdates) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  return buffer;
  }
function program5(depth0,data,depth1,depth2,depth3) {
  
  var buffer = "", stack1, stack2, options;
  buffer += "\n        <div class=\"sqs-tourdates__item\">\n          <div class=\"sqs-tourdates__info\">\n            <span class=\"sqs-tourdates__timeframe\" data-tour-datetime=\"";
  if (stack1 = helpers.datetime) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.datetime; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\">\n              <span class=\"sqs-tourdates__date\">";
  options = {hash:{
    'format': ("date")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['tourdates-bit-datetime'] || depth0['tourdates-bit-datetime']),stack1 ? stack1.call(depth0, depth0.datetime, options) : helperMissing.call(depth0, "tourdates-bit-datetime", depth0.datetime, options)))
    + "</span>\n              <span class=\"sqs-tourdates__weekday\">";
  options = {hash:{
    'format': ("weekday")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['tourdates-bit-datetime'] || depth0['tourdates-bit-datetime']),stack1 ? stack1.call(depth0, depth0.datetime, options) : helperMissing.call(depth0, "tourdates-bit-datetime", depth0.datetime, options)))
    + "</span>\n            </span>\n            <div class=\"sqs-tourdates__venuelocation\">\n              <div class=\"sqs-tourdates__venue\">\n                <a href=\"https://bandsintown.com/e/";
  if (stack2 = helpers.id) { stack2 = stack2.call(depth0, {hash:{},data:data}); }
  else { stack2 = depth0.id; stack2 = typeof stack2 === functionType ? stack2.apply(depth0) : stack2; }
  buffer += escapeExpression(stack2)
    + "\" class=\"sqs-tourdates__venueLink\" target=\"_blank\" rel=\"noopener\">\n                  <div class=\"sqs-tourdates__venue-name\">\n                    ";
  stack2 = helpers['if'].call(depth0, depth0.title, {hash:{},inverse:self.program(8, program8, data),fn:self.program(6, program6, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n                    @ ";
  options = {hash:{
    'format': ("time")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['tourdates-bit-datetime'] || depth0['tourdates-bit-datetime']),stack1 ? stack1.call(depth0, depth0.datetime, options) : helperMissing.call(depth0, "tourdates-bit-datetime", depth0.datetime, options)))
    + " ";
  stack2 = helpers['if'].call(depth0, ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.timezone), {hash:{},inverse:self.noop,fn:self.program(10, program10, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n                  </div>\n                  ";
  options = {hash:{
    'compare': (1)
  },inverse:self.noop,fn:self.program(12, program12, data),data:data};
  stack2 = ((stack1 = helpers.if_gt || depth0.if_gt),stack1 ? stack1.call(depth0, ((stack1 = depth0.lineup),stack1 == null || stack1 === false ? stack1 : stack1.length), options) : helperMissing.call(depth0, "if_gt", ((stack1 = depth0.lineup),stack1 == null || stack1 === false ? stack1 : stack1.length), options));
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n                </a>\n              </div>\n              <div class=\"sqs-tourdates__location\">\n                ";
  options = {hash:{},inverse:self.program(18, program18, data),fn:self.program(16, program16, data),data:data};
  stack2 = ((stack1 = helpers['equals-string'] || depth0['equals-string']),stack1 ? stack1.call(depth0, ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.type), "Virtual", options) : helperMissing.call(depth0, "equals-string", ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.type), "Virtual", options));
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n              </div>\n            </div>\n          </div>\n          <div class=\"sqs-tourdates__actions\">\n            ";
  stack2 = helpers.each.call(depth0, depth0.offers, {hash:{},inverse:self.noop,fn:self.programWithDepth(21, program21, data, depth3),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n\n            ";
  options = {hash:{},inverse:self.programWithDepth(32, program32, data, depth1, depth2),fn:self.programWithDepth(27, program27, data, depth1),data:data};
  stack2 = ((stack1 = helpers['equals-string'] || depth0['equals-string']),stack1 ? stack1.call(depth0, ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.type), "Virtual", options) : helperMissing.call(depth0, "equals-string", ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.type), "Virtual", options));
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n          </div>\n        </div>\n      ";
  return buffer;
  }
function program6(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                      ";
  if (stack1 = helpers.title) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.title; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\n                    ";
  return buffer;
  }

function program8(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                      "
    + escapeExpression(((stack1 = ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n                    ";
  return buffer;
  }

function program10(depth0,data) {
  
  var buffer = "", stack1, options;
  buffer += " ";
  options = {hash:{},data:data};
  buffer += escapeExpression(((stack1 = helpers['tourdates-timezone-abbr'] || depth0['tourdates-timezone-abbr']),stack1 ? stack1.call(depth0, ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.timezone), options) : helperMissing.call(depth0, "tourdates-timezone-abbr", ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.timezone), options)))
    + " ";
  return buffer;
  }

function program12(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                    <div class=\"sqs-tourdates__lineup\">";
  stack1 = helpers.each.call(depth0, depth0.lineup, {hash:{},inverse:self.noop,fn:self.program(13, program13, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</div>\n                  ";
  return buffer;
  }
function program13(depth0,data) {
  
  var stack1, stack2, options;
  options = {hash:{
    'compare': (0)
  },inverse:self.noop,fn:self.program(14, program14, data),data:data};
  stack2 = ((stack1 = helpers.if_gt || depth0.if_gt),stack1 ? stack1.call(depth0, ((stack1 = data),stack1 == null || stack1 === false ? stack1 : stack1.index), options) : helperMissing.call(depth0, "if_gt", ((stack1 = data),stack1 == null || stack1 === false ? stack1 : stack1.index), options));
  if(stack2 || stack2 === 0) { return stack2; }
  else { return ''; }
  }
function program14(depth0,data) {
  
  var buffer = "";
  buffer += "<div class=\"sqs-tourdates__lineup-item\">"
    + escapeExpression((typeof depth0 === functionType ? depth0.apply(depth0) : depth0))
    + "</div>";
  return buffer;
  }

function program16(depth0,data) {
  
  
  return "\n                ";
  }

function program18(depth0,data) {
  
  var buffer = "", stack1, stack2;
  buffer += "\n                  <a href=\"https://bandsintown.com/e/";
  if (stack1 = helpers.id) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.id; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\" class=\"sqs-tourdates__venue-link\" target=\"_blank\" rel=\"noopener\">\n                    "
    + escapeExpression(((stack1 = ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.city)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + ", ";
  stack2 = helpers['if'].call(depth0, ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.region), {hash:{},inverse:self.noop,fn:self.program(19, program19, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += escapeExpression(((stack1 = ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.country)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n                  </a>\n                ";
  return buffer;
  }
function program19(depth0,data) {
  
  var buffer = "", stack1;
  buffer += escapeExpression(((stack1 = ((stack1 = depth0.venue),stack1 == null || stack1 === false ? stack1 : stack1.region)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + ", ";
  return buffer;
  }

function program21(depth0,data,depth4) {
  
  var buffer = "", stack1, stack2, options;
  buffer += "\n              ";
  options = {hash:{},inverse:self.noop,fn:self.programWithDepth(22, program22, data, depth4),data:data};
  stack2 = ((stack1 = helpers['equals-string'] || depth0['equals-string']),stack1 ? stack1.call(depth0, depth0.status, "available", options) : helperMissing.call(depth0, "equals-string", depth0.status, "available", options));
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n            ";
  return buffer;
  }
function program22(depth0,data,depth5) {
  
  var buffer = "", stack1, stack2, options;
  buffer += "\n                <a\n                  href=\"";
  if (stack1 = helpers.url) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.url; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\"\n                  class=\"sqs-editable-button sqs-button-element--primary sqs-tourdates__button";
  options = {hash:{},inverse:self.noop,fn:self.program(23, program23, data),data:data};
  stack2 = ((stack1 = helpers['equals-string'] || depth0['equals-string']),stack1 ? stack1.call(depth0, depth0.type, "Sold Out", options) : helperMissing.call(depth0, "equals-string", depth0.type, "Sold Out", options));
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\"\n                  ";
  stack2 = helpers['if'].call(depth0, depth5.newWindow, {hash:{},inverse:self.noop,fn:self.program(25, program25, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n                >\n                  ";
  if (stack2 = helpers.type) { stack2 = stack2.call(depth0, {hash:{},data:data}); }
  else { stack2 = depth0.type; stack2 = typeof stack2 === functionType ? stack2.apply(depth0) : stack2; }
  buffer += escapeExpression(stack2)
    + "\n                </a>\n              ";
  return buffer;
  }
function program23(depth0,data) {
  
  
  return " sqs-tourdates__button--soldout";
  }

function program25(depth0,data) {
  
  
  return "target=\"_blank\" rel=\"noopener\"";
  }

function program27(depth0,data,depth2) {
  
  var buffer = "", stack1, stack2, options;
  buffer += "\n              ";
  options = {hash:{},inverse:self.programWithDepth(30, program30, data, depth2),fn:self.program(28, program28, data),data:data};
  stack2 = ((stack1 = helpers['is-live'] || depth0['is-live']),stack1 ? stack1.call(depth0, depth0.offers, options) : helperMissing.call(depth0, "is-live", depth0.offers, options));
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n            ";
  return buffer;
  }
function program28(depth0,data) {
  
  
  return "\n              ";
  }

function program30(depth0,data,depth3) {
  
  var buffer = "", stack1;
  buffer += "\n                <a\n                  href=\"";
  if (stack1 = helpers.url) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.url; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\" \n                  class=\"sqs-editable-button sqs-button-element--primary sqs-tourdates__button\"\n                  ";
  stack1 = helpers['if'].call(depth0, depth3.newWindow, {hash:{},inverse:self.noop,fn:self.program(25, program25, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                >\n                  "
    + escapeExpression(((stack1 = ((stack1 = depth3.strings),stack1 == null || stack1 === false ? stack1 : stack1.notifyMe)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n                </a>\n              ";
  return buffer;
  }

function program32(depth0,data,depth2,depth3) {
  
  var buffer = "", stack1;
  buffer += "\n              <a\n                href=\"";
  if (stack1 = helpers.url) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.url; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\"\n                class=\"sqs-editable-button sqs-button-element--primary sqs-tourdates__button\"\n                ";
  stack1 = helpers['if'].call(depth0, depth3.newWindow, {hash:{},inverse:self.noop,fn:self.program(25, program25, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n              >\n                "
    + escapeExpression(((stack1 = ((stack1 = depth2.strings),stack1 == null || stack1 === false ? stack1 : stack1.rsvp)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n              </a>\n            ";
  return buffer;
  }

function program34(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n      <div class=\"sqs-tourdates__item-no-results\">"
    + escapeExpression(((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.noResults)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n    ";
  return buffer;
  }

  buffer += "<script type=\"application/ld+json\">";
  if (stack1 = helpers.structuredData) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.structuredData; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</script>\n<div class=\"sqs-tourdates\">\n  ";
  stack1 = helpers['if'].call(depth0, depth0.loading, {hash:{},inverse:self.programWithDepth(3, program3, data, depth0),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>\n";
  return buffer;
  });})()

  var filename = 'tourdates-bandsintown-list.html';
  Y.Handlebars.registerPartial(filename.replace('/', '.'), Handlebars.templates[filename]);

}, '1.0', {
  requires: [
    "handlebars-base"
  ]
});
