const UrlCategory = Object.freeze({
  ANCHOR: 'ANCHOR',
  CONTENT_LINK: 'CONTENT_LINK',
  DEFAULT: 'DEFAULT',
  EMPTY_STRING: 'EMPTY_STRING',
  EXTERNAL_LINK: 'EXTERNAL_LINK',
  FILE_LINK: 'FILE_LINK',
  MALFORMED_PROTOCOL: 'MALFORMED_PROTOCOL',
  MISC_PROTOCOL: 'MISC_PROTOCOL'
});

export default UrlCategory;
