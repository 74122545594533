// @TODO switch to es6 imports
import trim from 'lodash/trim';
import querystring from 'qs';
import { collapseChar } from './StringUtils';
import UrlCategory from './UrlCategory';

const HTTPS_PROTOCOL = 'https:';
const TRANSFER_PROTOCOLS = ['http', 'https', 'ftp'];

// Matches text which begins with 'http(s)://'
const HTTP_REGEX = /^https?:\/\//i;
// Matches text with URI (i.e. spotify:, tel:, itunes:, mailto:, etc.)
const URI_REGEX = /[\w\:]*\:(\w+)/;
// Matches a properly formatted url which contains 'http(s)://' and/or 'www.'
const HTTP_WWW_REGEX = /(?:https?:\/\/)?(?:www\.)?(.*)\/?$/i;
// Matches a properly formatted url
const URL_REGEX = /^(https?:)?(\/\/)?[a-zA-Z0-9][a-zA-Z0-9_\-\.]*\.[a-zA-Z]{2,3}(\S*)?$/i;
// Matches text which begins with an anchor, relative path, supported URI scheme, or 'http(s)://'
const SUPPORTED_PROTOCOLS_REGEX = /(?:^#)|(?:^\.{0,2}\/)|(?:^(mailto|tel|sms|ftp|javascript):)|^(https?:)?\/\//; //eslint-disable-line
// Matches text which begins with 'http(s)://' or the 'ftp:' URI scheme
const PROTOCOL_REGEX = /^((https?:)?\/\/)|(ftp:\/\/)/i;
// Splits text which begins with a URI scheme-like pattern
const SPLIT_PROTOCOL_REGEX = /^([a-z]+):(.*)/i;
// Matches a properly formatted IP address
const IP_ADDRESS_REGEX = /^(https?:)?(\/\/)?[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+/i;
// Matches a properly formatted telephone number
const TELEPHONE_NUMBER_REGEX = /^[\+\-\d]{4,}$/;
// Matches a properly formatted telephone number for the sms URI scheme
// The '?body=' parameter is disallowed as iOS and Android require different formatting
const SMS_NUMBER_REGEX = /^([\+\-\(\)\d]*\d[\+\-\(\)\d]*,?)+$/;
// Matches a properly formatted top-level domain (e.g. example.com)
const TLD_REGEX = /\.[\w\d]+$/;

const URL_COLLECTION_REGEX = /^\/pages\/[0-9a-z]{24,24}$/;

/**
 * Removes all trailing slashes from a string.
 * If a string consists of only slashes, the first slash is preserved and the
 * rest are stripped/
 *
 * @param {string} [str=''] a/bc////
 * @return {string} a/bc
 */
const stripTrailingSlashes = (str = '') => str.replace(
  new RegExp(
    '^(\/?)' + // optional leading slash
    '(.*[^\/])?' + // everything else
    '(\/+)?$' // trailing slashes
  ),
  '$1$2' // removed trailing slashes
);

/**
 * @param {string}
 * @return {boolean}
 */
const isCollectionPanel = (url) => URL_COLLECTION_REGEX.test(url);

/**
 * Gets the UrlCategory of the url
 * @param {String} url
 * @returns {UrlCategory|null}
 */
const getCategory = (url) => {
  url = trim(url);

  if (url === '') {
    return UrlCategory.EMPTY_STRING;
  }

  /**
   * The problem we're solving here is that some links will cause the iframe to load "/config" inside itself,
   * creating an "inception" situation (CMS-15265). This is caused by all non-transfer protocols
   * (such as javascript/tel), and also when double-slash is omitted after the ":" (e.g. http:squarespace.com).
   */
  const parts = url.match(SPLIT_PROTOCOL_REGEX);
  if (parts && parts.length > 2) {
    const protocol = parts[1].toLowerCase();
    const hasDoubleSlashes = parts[2].startsWith('//');
    const isTransferProtocol = TRANSFER_PROTOCOLS.includes(protocol);

    /**
     * Will match javascript:alert('hi') and tel:7775554444
     */
    if (!isTransferProtocol) {
      // javascript, mailto, tel, sms, etc...
      return UrlCategory.MISC_PROTOCOL;
    }

    /**
     * Will match http:squarespace.com
     */
    if (!hasDoubleSlashes) {
      // malformed http, https, ftp without the double-slashes
      return UrlCategory.MALFORMED_PROTOCOL;
    }
  }

  /**
   * Will match #anchor
   */
  if (url.charAt(0) === '#') {
    return UrlCategory.ANCHOR;
  }

  /**
   * Will match https://squarespace.com
   */
  // eslint-disable-next-line no-use-before-define
  if (isLinkExternal(url)) {
    return UrlCategory.EXTERNAL_LINK;
  }

  /**
   * Will match /s/img.jpg
   */
  if (url.indexOf('/s/') === 0) {
    return UrlCategory.FILE_LINK;
  }

  /**
   * Will match /some-page
   */
  if (url.charAt(0) === '/') {
    return UrlCategory.CONTENT_LINK;
  }

  return null;
};

/**
 * Takes a URL an removes the protocol and slashes
 * Strips http://, https:// and ftp://
 * @private
 * @param {String} url
 * @return {String} stripped url
*/

const stripProtocolAndSlashes = (url) => {
  return url.replace(PROTOCOL_REGEX, '');
};

/**
 * Adds a query param to a url.
 *
 * @TODO consider using an existing lib or the DOM URL API for this
 * @param {String} [url=''] The url to be appended to.
 * @param {String} key The key (to your dreams).
 * @param {String} val The value (of your hard work).
 * @return {String} The new url with the apprened query param.
 *                  MESSES WITH HASH #abc#123 -> #abc,123
 */
const addQueryParam = (url = '', key, val) => {
  let hash = '';

  // Enforce string url (e.g. against null)
  if (!url || typeof url !== 'string') {
    // This used to throw fatal errors. Now we capture that someone is using
    // the function wrongly without breaking javascript execution on the page.
    if (window.SQUARESPACE_SENTRY) {
      window.SQUARESPACE_SENTRY.captureMessage('Invalid url passed to UrlUtils.addQueryParam');
    }
    url = '';
  }

  // this is not a url validator, use different util for that
  if (url) {
    // remove the hash, keep for reappending
    const _hashSplit = url.split('#');
    if (_hashSplit.length > 1) {
      // @TODO why is it joined with default , instead of #?
      hash = _hashSplit.slice(1).join();
      url = _hashSplit[0];
    }
  }

  // Append query params
  if (typeof key === 'string' && key) {
    const symbol = (url.indexOf('?') !== -1 ? '&' : '?');
    url += symbol + key + '=' + val;
  }

  // Re-append the hash if there is one
  if (hash) {
    url += '#' + hash;
  }

  return url;
};

/**
 * Helper for createUrlSafeString
 * @method createUrlWithSlash
 * @param  {String} val
 * @param  {Array} additionalAllowsChars  adds additional characters to allow in RegExp,
 * used for allowing variables that aren't url safe until after replaced
 * @return {String}
 */
const createUrlWithSlash = (val, additionalAllowedChars = []) => {
  const joinedAdditionalAllowedChars = additionalAllowedChars.join('');
  const allowedChars = `[^a-zA-Z0-9/\\-${joinedAdditionalAllowedChars}]`;

  val = val.trim()
    .replace(new RegExp('[ ]+', 'g'), ' ')
    .replace(new RegExp('[ ]', 'g'), '-')
    .replace(new RegExp(allowedChars, 'g'), '')
    .replace(new RegExp('[\\.\\-]{2,}', 'g'), '-')
    .replace(new RegExp('[\\.\\/]{2,}', 'g'), '/')
    .toLowerCase();

  return val;
};

/**
 * Only allow tokenized urls like "/%y/%m/%d/posts/%t"
 * Allows trailing '%' since you might be typing more
 */
const tokenizedBlogUrlInputFilter = (inputValue = '') => {
  // Strip preceding slash
  inputValue = inputValue.replace(/^\/+/g, '');
  inputValue = collapseChar(inputValue, '%');
  // If you try to type %anything other than %y %m %d %t, leave it as %
  inputValue = inputValue.replace(/%[^ymdt]/, '%');
  // Collapse double slashes
  inputValue = collapseChar(inputValue, '/');
  return inputValue ? createUrlWithSlash(inputValue, ['%']) : '';
};

const sanitizeTokenizedBlogUrl = (inputValue = '') =>
  stripTrailingSlashes(
    // strip trailing % that tokenizedBlogUrlInputFilter ignores
    tokenizedBlogUrlInputFilter(inputValue).replace(/%+$/, '')
  );

/**
 * Creates a url-safe slug out of an arbitrary string
 * @method createUrlSafeString
 * @param val {String} What to url encode
 * @param suffix {String} An extension to append to the url (optional)
 * @return {String} The url encoded value
 */
const createUrlSafeString = (val, suffix) => {
  let v = val;

  if (suffix) {
    v += suffix;
  }

  v = v.replace(/\//g, '');
  return createUrlWithSlash(v);
};


/**
 * Whether or not a given url starts with a given string && has more length than that string
 *
 * @method doesStringStartWith
 * @param url {String} url to test
 * @param prefix {String} string that URL must start with
 * @returns {boolean}
 * @private
 */
const doesStringStartWith = (url, prefix) => {
  return !!url && url.search(new RegExp('^' + prefix + '.+', 'i')) === 0;
};

// These will specifically be double encoded in #doubleEncode()
const thingsToDoubleEncode = [
  [/\//g, '%2F'],
  [/\?/g, '%3F'],
  [/\*/g, '%2A'],
];

/**
 * This is a temporary patch that url encodes forward slashes, question marks,
 * and asterisks twice for the bandsintown widget
 *
 * @param {string} field
 * @see {@link https://jira.squarespace.net/browse/CMS-994}
 */
const doubleEncode = (field) => {
  // Encode the things above using regex string replace
  const encoded = thingsToDoubleEncode.reduce(function (encodedField, entity) {
    return encodedField.replace(entity[0], entity[1]);
  }, field);
  // Encode the whole thing again
  return encodeURIComponent(encoded);
};

/**
 * Take the given data object and convert it into a url-encoded string
 *
 * @param {Object} data - The data to convert into FormData.
 * @return {String} The encoded data.
 */
const encodeUrl = (data) => {
  const str = [];

  Object.keys(data).forEach(key => {
    if (data.hasOwnProperty(key) && data[key]) {
      const val = typeof data[key] === 'string' ? data[key] : JSON.stringify(data[key]);
      str.push(encodeURIComponent(key) + '=' + encodeURIComponent(val));
    }
  });

  return str.join('&');
};

/**
 * Creates a DOM element from a url
 * @param {String} url
 * @private
*/
const getNode = (url) => {
  const el = document.createElement('a');
  el.href = url;

  return el;
};

/**
 * Use a DOM node to get path of url
 * @param {String} url
 * @return {String}
*/
const getPath = (url) => {
  url = getNode(url);

  return url.pathname;
};

const getSearch = (url) => {
  url = getNode(url);

  return url.search;
};

const getHash = (url) => {
  url = getNode(url);

  return url.hash;
};

const getHostName = (link) => {
  const noProtocolLink = stripProtocolAndSlashes(link);
  const linkHost = noProtocolLink.split('/')[0];

  return linkHost;
};

const pathMatches = (urlA, urlB) => {
  urlA = getNode(urlA);
  urlB = getNode(urlB);

  return urlA.pathname === urlB.pathname;
};


/**
 * Test if url starts with an external protocol
 * @param {String} url
 * @return {Bool}
 *
 */
const hasExternalProtocol = (url) => {
  return PROTOCOL_REGEX.test(url);
};

/**
 * Checks whether or not given string is /.
 *
 * @method isSlash
 * @param  {String} url Url to check
 * @return {Boolean}
 */
const isSlash = (url) => {
  return (url === '/');
};


/**
 * Ensure url always has a protocol.
 * Will give http:// as a default if url doesn't specify one.
 *
 * @method ensureProtocol
 * @param  {String} url The url to check
 * @return {String} url
 */
const ensureProtocol = (url) => {

  if (url.search(HTTP_REGEX) < 0) {
    url = 'http://' + url.replace(/^(\/\/)+/, '');

    // ensure no more than one protocol is included.
  } else {
    url = url.replace(PROTOCOL_REGEX, function (match, lastProtocol) {
      return lastProtocol;
    });
  }

  return url;

};


/**
 * Ensure url always has a protocol.
 * Will give http:// as a default if url doesn't specify one.
 * This one supports URIs such as tel:123456 and spotify:user:asdfasdfasdf
 *
 * @method ensureProtocol
 * @param  {String} url The url to check
 * @return {String} url
 */
const ensureUriOrProtocol = (url) => {

  if (url.search(URI_REGEX) < 0) {
    return ensureProtocol(url);
  }

  return url;
};

/**
 * Ensure at least 1 proper prefix
 *
 * @param {String} url
 */
const ensurePrefixedUrl = (url) => {
  if (url) {
    if (SUPPORTED_PROTOCOLS_REGEX.test(url)) {
      return url;
    }
    return 'http://' + stripProtocolAndSlashes(url);
  }

  return '';
};

/**
 * Determines if a link is to an external domain.
 * (i.e. has a protocol and points outside squarespace.com)
 * Location is injected for testing purposes
 *
 * @param {String} url
 * @param {String} [loc=window.location.host] - dependency injection for testing
 * @return {Bool}
*/
const isLinkExternal = (url, host = window.location.host) => {
  if (!url || !hasExternalProtocol(url)) {
    return false;
  }

  const noProtocolUrl = stripProtocolAndSlashes(url);
  const linkHost = noProtocolUrl.split('/')[0];

  return linkHost.toLowerCase() !== host.toLowerCase();
};

/**
 * Checks whether or not given string is truly a proper url.
 *
 * @method isProperUrl
 * @param  {String} url Url to check
 * @return {Boolean}
 */
const isProperUrl = (url) => {
  // This is not permissive enough: We are going to need to support foreign characters, etc.
  // SIX-15133 Support underscores in subdomains (not part of spec, but apparently, it's a thing)
  return (url.search(URL_REGEX) > -1);
};

/**
 * Checks whether or not given string is truly an IP.
 *
 * @method isIP
 * @param  {String} url Url to check
 * @return {Boolean}
 */
const isIP = (url) => {
  return url.search(IP_ADDRESS_REGEX) > -1;
};

/**
 * Checks whether or not the given string is an anchor
 * @method isAnchor
 * @param url {String}
 * @returns {boolean}
 */
const isAnchor = (url) => {
  return doesStringStartWith(url, '#') && url.length < 100;
};

/**
 * Checks whether or not the given string is a telephone link
 * @method isTel
 * @param url {String}
 * @returns {boolean}
 */
const isTel = (url) => {
  return doesStringStartWith(url, 'tel:') && url.length < 50;
};

/**
 * Whether the url is a loosely proper tel link url
 * @param {String} url
 */
const isProperTel = (url) => {
  return isTel(url) && TELEPHONE_NUMBER_REGEX.test(url.split('tel:')[1]);
};

/**
 * Checks whether or not the given string is a sms link
 * @method isSms
 * @param url {String}
 * @returns {boolean}
 */
const isSms = (url) => {
  return doesStringStartWith(url, 'sms:') && url.length < 1000;
};

/**
 * Whether the url is a loosely proper sms link url
 * @param {String} url
 */
const isProperSms = (url) => {
  if (!isSms(url)) {
    return false;
  }

  return SMS_NUMBER_REGEX.test(url.split('sms:')[1]);
};

/**
 * Checks whether or not the given string is a mailto link
 * @method isMailto
 * @param url {String}
 * @returns {boolean}
 */
const isMailto = (url) => {
  return doesStringStartWith(url, 'mailto:') && url.length < 1000;
};

/**
 * Whether the url is a loosely proper mailto link
 * @param {String} url
 */
const isProperMailTo = (url) => {
  if (!isMailto(url)) {
    return false;
  }
  const email = url.split('?')[0];
  const strings = email.split('@');
  return strings.length === 2 && TLD_REGEX.test(strings[1]);
};

/**
 * Checks whether or not the given string is a Javascript link
 * @method isJavascript
 * @param url {String}
 * @returns {boolean}
 */
const isJavascript = (url) => {
  return doesStringStartWith(url, 'javascript:') && url.length < 250;   //eslint-disable-line
};

/**
 * Checks whether or not the given string is a Ftp link
 * @method isFtp
 * @param url {String}
 * @returns {boolean}
 */
const isFtp = (url) => {
  return doesStringStartWith(url, 'ftp:') && url.length < 100;
};

/**
 * Checks whether or not the given string is in internal link
 * @method isInternalUrl
 * @param url {String}
 * @returns {boolean}
 */
const isInternalUrl = (url) => {
  return doesStringStartWith(url, '\\/[^\\/]') && url.length < 150;
};

/**
 * Determine whether current URL (or optional passed in URL) is https
 *
 * @method isSecure
 * @returns Boolean
 */
const isSecure = (url) => {
  let protocol = '';
  if (url) {
    protocol = (url + '').toLowerCase().substring(0, 6);
  } else if (window) {
    protocol = window.location.protocol;
  }
  return protocol === HTTPS_PROTOCOL;
};


const securifyURL = (url) => {
  if (!url) {
    return url;
  }

  return HTTPS_PROTOCOL + '//' + stripProtocolAndSlashes(url);
};

/**
 * Turns non SSL Flash Embed into SSL one.
 *
 * @method  securifyBlockEmbed
 * @param  blockNode {Node}  Block element which contains embed
 * @param  [triggerReflow] {Boolean}  determines whether to trigger reflow
 */
const securifyBlockEmbed = (blockNode, triggerReflow) => {

  if (!isSecure() || !blockNode) {
    return;
  }

  const embeds = blockNode.all(('object embed, iframe'));
  const length = embeds.size();

  embeds.each(function (embed, index) {

    const src = embed.getAttribute('src');
    if (src) {
      embed.setAttribute('src', securifyURL(src));
    }

    if (triggerReflow && length === index + 1) {
      embed.setStyle('display', 'none');
      embed.setStyle('display', null);
    }

  });

};

/**
 * Takes a URL, and returns it without the protocol or www (if present)
 * @param {String} the url to adjust
 */
const removeHttpAndWwwFromUrl = (url) => {
  return url.replace(HTTP_WWW_REGEX, '$1');
};

/**
 * Add 'http://' to an url if it's missing and the user have not started to add it
 * @param {String} url
 */
const addHttpIfMissing = (url = '') => {
  const willBeMissing = (
    'https://'.startsWith(url.slice(0, 8)) ||
    'http://'.startsWith(url.slice(0, 7)) ||
    'ftp://'.startsWith(url.slice(0, 6))
  );
  return willBeMissing ? url : `http://${url}`;
};


/**
 * Convert a content object into an internal URL
 * @param {Object} content Information about the internal page
 */
const constructUrlFromContent = ({ filters, urlId }) => {
  const query = filters ? querystring.stringify(filters, { arrayFormat: 'repeat' }) : '';

  return `/${urlId}` + (query ? `?${query}` : '');
};

/**
 * Extract the parameters if the parameter is an internal URl
 * @param {String} url Url to deconstruct
 */
const deconstructUrlToContent = url => {
  if (!isInternalUrl(url)) {
    return {};
  }
  const [ urlId, queries ] = url.replace(/^\//, '').split('?');
  const filters = queries ? querystring.parse(queries) : null;

  return {
    urlId,
    filters
  };
};

export {
  addHttpIfMissing,
  addQueryParam,
  constructUrlFromContent,
  createUrlSafeString,
  createUrlWithSlash,
  deconstructUrlToContent,
  doubleEncode,
  encodeUrl,
  ensurePrefixedUrl,
  ensureProtocol,
  ensureUriOrProtocol,
  getNode,
  getCategory,
  getHash,
  getHostName,
  getPath,
  getSearch,
  hasExternalProtocol,
  isAnchor,
  isCollectionPanel,
  isFtp,
  isInternalUrl,
  isIP,
  isJavascript,
  isLinkExternal,
  isMailto,
  isProperMailTo,
  isProperSms,
  isProperTel,
  isProperUrl,
  isSecure,
  isSlash,
  isSms,
  isTel,
  pathMatches,
  removeHttpAndWwwFromUrl,
  sanitizeTokenizedBlogUrl,
  securifyBlockEmbed,
  securifyURL,
  stripProtocolAndSlashes,
  stripTrailingSlashes,
  tokenizedBlogUrlInputFilter,
  URI_REGEX
};
