import padStart from 'lodash/padStart';
import noop from 'lodash/noop';
import moment from 'moment';
import escapedStringify from '@sqs/utils/json/escapedStringify';
import { t, formatDateTime } from 'shared/i18n';
import network from '@sqs/network';
import { doubleEncode } from 'shared/utils/UrlUtils';
// Ensure that moment.tz is defined when visitors load the site
import momentTimezone from 'moment-timezone';

/**
 * Renderer for Tour Dates via Bandsintown
 *
 * @module squarespace-widgets-tourdates-bandsintown
 */
YUI.add('squarespace-widgets-tourdates-bandsintown', function (Y) {

  var MKT_SOURCE = 'mkt_source';

  var UTM_PARAMETERS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  MKT_SOURCE];


  /**
   * Format a date to 2001-01-01
   *
   * @method formatDate
   * @param  {Date}   date The date to format.
   * @return {String}  The formatted string
   */
  function formatDate(date) {
    return [
    date.getFullYear(),
    padStart(date.getMonth() + 1, 2, '0'),
    padStart(date.getDate(), 2, '0')].
    join('-');
  }

  function addQueryParametersToUrl(url, parameters) {
    return url + (url.includes('?') ? '&' : '?') + Y.QueryString.stringify(parameters);
  }

  /**
   * Stores the result of a query to the bandsintown API
   *
   * @class TourDatesModelList
   * @extends ModelList
   * @example
   *  https://rest.bandsintown.com/artists/Minus%20the%20Bear/events?app_id=1
   */
  var TourDatesModelList = Y.Base.create('bandsInTownTourDatesModelList', Y.ModelList, [], {
    sync: function (action, options, callback) {
      if (action !== 'read') {
        console.warn('[TourDatesModelList] Unsupported sync action: ' + action);
        return;
      }

      callback = Y.Lang.isFunction(callback) ? callback : noop;

      var websiteId = Y.Object.getValue(Static, ['SQUARESPACE_CONTEXT', 'website', 'identifier']);
      var appId = 'squarespace-' + websiteId; // Bandsintown requires app_id passed with every request

      var timeframe;
      var artistId;
      if (!this.get('artistId')) {
        // Show some example content
        artistId = encodeURIComponent('Morrissey');
        timeframe = '2015-11-01,2015-12-31';
      } else {
        // Due to a bug in Bandsintown's API, double quotes must be converted
        // to single (API only, not website links)
        // See https://jira.squarespace.net/browse/CMS-994
        artistId = doubleEncode(this.get('artistId').replace(/"/g, "'"));
        timeframe = this.get('timeframe') === 'dateRange' ?
        formatDate(new Date(this.get('startDate'))) + ',' + formatDate(new Date(this.get('endDate'))) :
        'upcoming';
      }

      var url = 'https://rest.bandsintown.com/artists/' + artistId +
      '/events?app_id=' + appId + '&date=' + timeframe;

      if (__DEV__) {
        console.log('[tourdates-bandsintown] API request', url);
      }

      network.get(url).
      then(function (response) {
        callback(null, response.data);
      }).
      catch(function (err) {
        callback(err);
      });
    } },

  {

    ATTRS: {
      artistId: { value: null },
      timeframe: { value: null },
      startDate: { value: null },
      endDate: { value: null } } });




  Y.namespace('Squarespace.Widgets');

  /**
   * @namespace   Squarespace.Widgets
   * @class       BandsInTownTourDatesList
   * @extends     Squarespace.SSWidget
   */
  var BandsInTownTourDatesList =
  Y.Squarespace.Widgets.BandsInTownTourDatesList =
  Y.Base.create('bandsInTownTourDatesList', Y.Squarespace.SSWidget, [], {

    initializer: function () {
      this.onceAfter('loadedChange', this._onLoaded, this);
      this.get('modelList').load(Y.bind(function () {
        this.set('loaded', true);
      }, this));
    },

    _onLoaded: function () {
      this._registerEvent(
      Y.on('windowresize', Y.bind(function () {
        if (this._venues.size()) {
          this._setFlexDirection();
        }
      }, this)));


      this._registerEvent(Y.on('domready', function () {
        this.renderUI();
      }, this));
    },

    renderUI: function () {
      BandsInTownTourDatesList.superclass.renderUI.call(this);

      var bb = this.get('boundingBox');

      if (!this.get('loaded')) {
        if (!this.spinner) {
          this.spinner = new Y.Squarespace.Spinner({
            size: 30,
            color: 'dark',
            render: bb });

        }
        return;
      }

      var cb = this.get('contentBox');

      this.spinner.destroy();
      cb.empty().append(this.renderTemplate());
      this.appendStructuredData();
      bb.addClass('loaded');
      if (!this.get('artistId')) {
        bb.setAttribute('data-block-state', 'uninitialized');
        bb.addClass('sqs-block-is-placeholder');
      }
      this._venues = cb.all('.sqs-tourdates__venue-name');
      this._setFlexDirection();
      this.fire('templateRendered');
    },

    _setFlexDirection: function () {
      var cb = this.get('contentBox');
      if (!cb.getDOMNode()) {
        return;
      }

      cb.removeClass('sqs-tourdates--venuelocation--column');
      cb.removeClass('sqs-tourdates--info--column');

      // First, check if just the venues are wrapped
      var first = this._venues.item(0);
      if (first) {
        var firstX = first.getX();
        var isAnyVenueWrapped = this._venues.some(function (item) {
          return item.getX() !== firstX;
        });
        if (isAnyVenueWrapped) {
          cb.addClass('sqs-tourdates--venuelocation--column');
        }

        // Repeat, for if the entire info section needs to wrap
        firstX = first.getX();
        isAnyVenueWrapped = this._venues.some(function (item) {
          return item.getX() !== firstX;
        });
        if (isAnyVenueWrapped) {
          cb.addClass('sqs-tourdates--info--column');
        }
      }
    },

    /**
     * Appends a new script tag (type=ld+json) onto the page for consumption as
     * structured data.
     *
     * The original script tag is found within the handlebars template. After
     * that is compiled, this method appends it back onto the page in a new
     * script tag so that it is crawlable.
     *
     * @method appendStructuredData
     */
    appendStructuredData: function () {
      var scriptEl = document.createElement('script');
      scriptEl.type = 'application/ld+json';
      scriptEl.text = this.get('contentBox').one('script').get('innerHTML');
      Y.one('head').append(scriptEl);
    },

    getTourDateUrlAdditionalParams: function () {
      var search = Y.config.win.location.search.slice(1);

      var parsedSearch = Y.QueryString.parse(search);
      var params = Object.keys(parsedSearch).filter(function (key) {
        return UTM_PARAMETERS.includes(key);
      }).reduce(function (dict, key) {
        dict[key] = parsedSearch[key];
        return dict;
      }, {});
      if (!params.hasOwnProperty(MKT_SOURCE)) {
        params[MKT_SOURCE] = Y.config.win.location.hostname;
      }
      return params;
    },

    responseToStructuredData: function (d) {
      var artist;
      // Repair the lineup setting WITHOUT assuming the artist will actually
      // be at the event so we are not at fault for any assumptions.
      if (!d.lineup || !d.lineup.length) {
        console.error('[tourdates-bandsintown] event is missing lineup', d);
        artist = t("artist not listed");
      } else {
        artist = d.lineup[0];
      }

      var name = t("{artist} @ {venue} in {city}, {region}",

      {
        artist: artist,
        venue: d.venue.name,
        city: d.venue.city,
        region: d.venue.country === 'United States' ? d.venue.region : d.venue.country });




      var performer = {
        '@type': 'MusicGroup',
        name: artist,
        url: 'https://www.bandsintown.com/' + doubleEncode(artist) };


      var offers = d.offers.map(function (o) {
        return {
          '@type': 'Offer',
          url: o.url,
          availability: o.status,
          category: o.type };

      });

      return {
        '@context': 'https://schema.org',
        '@type': 'MusicEvent',
        name: name,
        url: d.url,
        startDate: d.datetime,
        location: {
          '@type': 'Place',
          name: d.venue.name,
          address: {
            '@type': 'PostalAddress',
            'addressCountry': d.venue.country,
            'addressLocality': d.venue.city,
            'addressRegion': d.venue.region },

          geo: {
            '@type': 'GeoCoordinates',
            latitude: d.venue.latitude,
            longitude: d.venue.longitude } },


        performer: performer,
        offers: offers };

    },

    /**
     * @override
     */
    _getHBTemplateContext: function () {
      var context = {
        loading: !this.get('loaded'),
        strings: {
          rsvp: t("RSVP"),
          noResults: t("There are no upcoming tour dates."),
          loading: t("Loading tour dates..."),
          notifyMe: t("Notify Me") },

        newWindow: !Y.UA.mobile };


      // Loading
      if (!this.get('loaded')) {
        return context;
      }

      // No upcoming
      var list = this.get('modelList').toJSON();
      if (!list.length) {
        return context;
      }

      // Start parsing - clone object and append params to urls
      var additionalParams = this.getTourDateUrlAdditionalParams();
      context.tourdates = list.map(function (entry) {
        return !entry.url ? entry : Y.merge({}, entry, {
          url: addQueryParametersToUrl(entry.url, additionalParams),
          offers: entry.offers.map(function (offer) {
            return !offer.url ? offer : Y.merge({}, offer, {
              url: addQueryParametersToUrl(offer.url, additionalParams) });

          }) });

      });

      var structuredData = list.
      map(this.responseToStructuredData).
      filter(function (data) {return !!data;});
      context.structuredData = escapedStringify(structuredData, null, 2);
      return context;
    } },

  {

    CSS_PREFIX: 'sqs-tourdates-bandsintown-list',

    HANDLEBARS_TEMPLATE: 'tourdates-bandsintown-list.html',

    ATTRS: {
      artistId: { value: null },
      timeframe: { value: null },
      startDate: { value: null },
      endDate: { value: null },
      modelList: {
        valueFn: function () {
          return new TourDatesModelList(this.getAttrs(['artistId', 'timeframe', 'startDate', 'endDate']));
        } },

      loaded: { value: false } } });




  /**
   * Handlebars helper to turn an ISO timestamp into Bandsintown
   * Block-specific date formats.
   * It's a hack to bypass unnecessary complexity and problems with regard to
   * timezones and daylight savings time when all we want to do is format the
   * time exactly as supplied by the data response.
   *
   * @param  {String} date    ISO timestamp
   * @param  {Object} options format (date or weekday)
   * @return {String} Abbreviated month label and day of month
   */
  Y.Handlebars.registerHelper('tourdates-bit-datetime', function (date, options) {
    // The data response provides an ISO timestamp with no timezone, but let's be sure that's all we're working with,
    // in case they change things.
    var dateString = date.match(/^(\d{4}\-\d\d\-\d\d([tT][\d:\.]*)?)([zZ]|([+\-])(\d\d):?(\d\d))?$/)[1];
    var d = moment.utc(dateString);
    if (options.hash.format === 'date') {
      return formatDateTime(d, 'll');
    } else if (options.hash.format === 'weekday') {
      return formatDateTime(d, 'ddd');
    } else if (options.hash.format === 'time') {
      return formatDateTime(d, 'LT');
    }
  });

  Y.Handlebars.registerHelper('tourdates-timezone-abbr', function (timezone) {
    // following will always return valid string
    return momentTimezone.tz(timezone).zoneAbbr();
  });

  Y.Handlebars.registerHelper('equals-string', function (field, value, options) {
    return Y.Handlebars.helpers.if.call(this, function () {
      return field === value.toString();
    }, options);
  });

  Y.Handlebars.registerHelper('is-live', function (offers, options) {
    return Y.Handlebars.helpers.if.call(this, function () {
      var liveOffers = offers.filter(function (offer) {
        // "Watch Live" comes from the Bandsintown API and is the current best way to detect a live event
        return offer.type === 'Watch Live';
      });
      return liveOffers.length > 0;
    }, options);
  });

  Y.Handlebars.registerHelper('url-encode', function (field) {
    return encodeURIComponent(field);
  });

  Y.Handlebars.registerHelper('url-encode-patch', doubleEncode);

}, '1.0', {
  requires: [
  'jsonp',
  'model-list',
  'squarespace-hb-compare',
  'squarespace-promises',
  'squarespace-spinner',
  'squarespace-ss-widget',
  'squarespace-tourdates-bandsintown-list-template'] });