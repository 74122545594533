// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/tourdates.css';

YUI.add('squarespace-tourdates', function (Y) {

  Y.namespace('Squarespace.Tourdates');

  var tourdatesInstances = [];

  Y.Squarespace.Tourdates.initializeNode = function(el) {
    // Has this block already been inserted into the DOM
    var isBlockInitialized = !!Y.Array.find(tourdatesInstances, function(tourdatesInstance) {
      return tourdatesInstance.get('boundingBox').ancestor('.sqs-block') === el;
    });

    if (isBlockInitialized) {
      return;
    }
    var blockJson = JSON.parse(el.getAttribute('data-block-json'));
    var widget = new Y.Squarespace.Widgets.BandsInTownTourDatesList({
      artistId: blockJson.artistId,
      timeframe: blockJson.timeframe,
      startDate: blockJson.startDate,
      endDate: blockJson.endDate
    }).render(el);
    tourdatesInstances.push(widget);
  };

  Y.config.win.Squarespace.onInitialize(Y, function initTourDates() {
    Y.all('.sqs-block-tourdates[data-block-json]').each(Y.Squarespace.Tourdates.initializeNode);
  });

  /**
   * @squarespace/mercury integration
   */
  Y.config.win.Squarespace.onDestroy(Y, function destroyTourDates() {
    tourdatesInstances.forEach(function (widget) {
      if (widget.destroy) {
        widget.destroy();
      }
    });
    Y.all('.sqs-block-tourdates[data-block-json]').each(function (el) {
      el.empty();
    });
    tourdatesInstances.length = 0;
  });

}, '1.0', {
  requires: [
    'squarespace-widgets-tourdates-bandsintown'
  ]
});

